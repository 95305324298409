import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import logo from "../../Login/tradinlogo.png";
import tradinAILogo from "../../../assets/img/TradinAI_Logo.jpg";

const topBar = () => {
  return (
    <div className="top-bar-container">
      <img className="home-nav-logo" src={tradinAILogo} alt="logo"></img>

      <div className="top-bar-signin">
        <Link className="link-unstyled" to="/launch">
          Launch Page
        </Link>
      </div>
    </div>
    /* <div className='top-bar pt-1 pb-1 text-light'>
      <div className='container'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex pt-2 pb-2'>
            <div className='d-flex align-item-center mr-3'>
              <i className='fas fa-phone-alt mr-2' />
              <small>+213 (0) 123 456 789</small>
            </div>
            <div className='d-flex align-item-center'>
              <i className='fas fa-envelope mr-2' />
              <small>contact@email.com</small>
            </div>
          </div>
          <div className='d-flex pt-2 pb-2'>
            <div className='social-media d-flex align-item-center'>
              <a href='!#' className='text-light'>
                <i className='fab fa-facebook-f mr-4' />
              </a>
              <a href='!#' className='text-light'>
                <i className='fab fa-twitter mr-4' />
              </a>
              <a href='!#' className='text-light'>
                <i className='fab fa-instagram mr-4' />
              </a>
              <a href='!#' className='text-light'>
                <i className='fab fa-github' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>*/
  );
};

export default topBar;
